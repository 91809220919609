<template>
  <v-form v-if="dataReport" ref="formReport">
    <v-card flat class="pa-4 mt-4">
      <div class="mb-6">
        <v-row no-gutters align="center" class="font-weight-bold mb-2">
          <div class="dot-primary mr-2"></div>
          {{ $t("report_card.attitude_score_title") }}
        </v-row>

        <v-data-table
          :headers="[
            {
              text: $t('report_card.attitude_score_attitude'),
              value: 'name',
              sortable: false
            },
            {
              text: $t('report_card.score_title'),
              value: 'score',
              sortable: false
            },
            {
              text: $t('report_card.attitude_score_rating'),
              value: 'grade',
              sortable: false
            },
            {
              text: $t('app.description'),
              value: 'description',
              sortable: false
            }
          ]"
          :items="dataReport.atitude"
          class="elevation-0"
          hide-default-footer
        >
        </v-data-table>
      </div>

      <v-divider class="my-6"></v-divider>

      <div class="mb-6">
        <v-row no-gutters align="center" class="font-weight-bold mb-2">
          <div class="dot-primary mr-2"></div>
          {{ $t("report_card.score_title") }}
        </v-row>

        <!-- <v-spacer></v-spacer> -->
        <!-- {{ $t("report_card.completeness_criteria") }}
          <span class="ml-2">= {{ dataReport.completeness_criteria }}</span> -->

        <!-- dataReport.score -->
        <div
          v-for="(s, idx) in dataReport.score"
          :key="`${idx}-score`"
          class="mb-4"
        >
          <v-row no-gutters class="mb-2">
            <div>Kelompok {{ s.lesson_group }} :</div>
            <div class="font-weight-bold ml-2">
              {{ s.lesson_group_name }}
            </div>
          </v-row>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" rowspan="2">
                    {{ $t("app.subject") }}
                  </th>
                  <th class="text-left" colspan="3">
                    Nilai Pengetahuan (K-3)
                  </th>
                  <th class="text-left" colspan="3">
                    Nilai Pengetahuan (K-4)
                  </th>
                </tr>
                <tr>
                  <th class="text-left">Angka</th>
                  <th class="text-left">Predikat</th>
                  <th class="text-left">Deskripsi</th>
                  <th class="text-left">Angka</th>
                  <th class="text-left">Predikat</th>
                  <th class="text-left">Deskripsi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(m, idxm) in s.member" :key="idxm">
                  <td>{{ m.lesson_master_name }}</td>
                  <td>{{ m.ki_3 }}</td>
                  <td>{{ m.ki_3_grade }}</td>
                  <td>{{ m.ki_3_desc }}</td>
                  <td>{{ m.ki_4 }}</td>
                  <td>{{ m.ki_4_grade }}</td>
                  <td>{{ m.ki_4_desc }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <!-- <div class="mt-2">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td colspan="2">Total Nilai</td>
                  <td colspan="3">
                    <span>K-3</span><br />
                    <b>{{ dataReport.score_summary.total_ki3 }}</b>
                  </td>
                  <td colspan="3">
                    <span>K-4</span><br />
                    <b>{{ dataReport.score_summary.total_ki4 }}</b>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">Rata - Rata</td>
                  <td colspan="3">
                    <span>K-3</span><br />
                    <b>{{ dataReport.score_summary.avg_ki3 }}</b>
                  </td>
                  <td colspan="3">
                    <span>K-4</span><br />
                    <b>{{ dataReport.score_summary.avg_ki4 }}</b>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div> -->
      </div>

      <v-divider class="my-6"></v-divider>

      <div class="mb-6">
        <v-row no-gutters align="center" class="font-weight-bold mb-2">
          <div class="dot-primary mr-2"></div>
          {{ $t("report_card.extracuricular") }}
        </v-row>
        <v-simple-table v-if="dataReport.extracurricullar.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" style="width: 60px">
                  {{ $t("report_card.number") }}
                </th>
                <th class="text-left">
                  {{ $t("report_card.extracuricular_activities") }}
                </th>
                <th class="text-left">
                  {{ $t("report_card.extracuricular_rating") }}
                </th>
                <th class="text-left">{{ $t("app.description") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(e, idx) in dataReport.extracurricullar"
                :key="`${idx}-attitude`"
              >
                <td style="width: 60px">{{ idx + 1 }}.</td>
                <td class="width200">{{ e.exschool_name }}</td>
                <td>{{ e.grade }}</td>
                <td>{{ e.description }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-else>{{ $t("app.no_data") }}</div>
      </div>

      <v-divider class="my-6"></v-divider>

      <div class="mb-6">
        <v-row no-gutters align="center" class="font-weight-bold mb-2">
          <div class="dot-primary mr-2"></div>
          {{ $t("report_card.achievement") }}
        </v-row>

        <v-simple-table v-if="dataReport.achievement.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" style="width: 60px">
                  {{ $t("report_card.number") }}
                </th>
                <th class="text-left">{{ $t("app.description") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(a, idx) in dataReport.achievement"
                :key="`${idx}-attitude`"
              >
                <td style="width: 60px">{{ idx + 1 }}.</td>
                <td>{{ a.description }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-else>{{ $t("app.no_data") }}</div>
      </div>

      <v-divider class="my-6"></v-divider>

      <div class="mb-6">
        <v-row no-gutters align="center" class="font-weight-bold mb-2">
          <div class="dot-primary mr-2"></div>
          {{ $t("report_card.absence") }}
        </v-row>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" style="width: 60px">
                  {{ $t("report_card.number") }}
                </th>
                <th class="text-left">
                  {{ $t("report_card.absence_type") }}
                </th>
                <th class="text-left">{{ $t("elearning.table.total") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td>{{ $t("report_card.sick") }}</td>
                <td>{{ dataReport.absence.sick }}</td>
              </tr>
              <tr>
                <td>2.</td>
                <td>{{ $t("report_card.leave") }}</td>
                <td>{{ dataReport.absence.leave }}</td>
              </tr>
              <tr>
                <td>3.</td>
                <td>{{ $t("report_card.alpha") }}</td>
                <td>{{ dataReport.absence.alpha }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <v-divider class="my-6"></v-divider>

      <div class="mb-6">
        <v-row no-gutters align="center" class="font-weight-bold mb-2">
          <div class="dot-primary mr-2"></div>
          {{ $t("report_card.field_practice") }}
        </v-row>
        <span v-html="dataReport.partical_work"></span>
      </div>

      <v-divider class="my-6"></v-divider>

      <div class="mb-6">
        <v-row no-gutters align="center" class="font-weight-bold mb-2">
          <div class="dot-primary mr-2"></div>
          {{ $t("report_card.homeroom_notes") }}
        </v-row>
        <span v-html="dataReport.summary"></span>
      </div>

      <v-divider class="my-6"></v-divider>

      <v-card flat class="mt-12">
        <v-row no-gutters justify="space-around">
          <div class="text-center">
            <div class="mb-2">
              Mengetahui,<br />
              Orang Tua/Wali
            </div>

            <div class="mb-12">Guru Kelas</div>
            <div>( {{ dataReport.footer.guardian.name }} )</div>
          </div>
          <div class="text-center">
            <div class="mb-12">
              Guru Kelas
            </div>

            <div class="pt-8">
              {{ dataReport.footer.homeroom.name }}<br />
              NIP: {{ dataReport.footer.homeroom.nip }}
            </div>
          </div>
        </v-row>
      </v-card>
    </v-card>
  </v-form>
</template>

<script>
export default {
  props: {
    dataReport: Object
  }
};
</script>

<style lang="scss">
.dot-primary {
  width: 18px;
  height: 18px;
  border: 5px solid #27ace2;
  border-radius: 50%;
  display: inline-block;
}
</style>
