import request from "@/utils/request";

export function getReportList(data) {
  return request({
    method: "post",
    url: "/report/list",
    data: data
  });
}

export function generateReport(data) {
  return request({
    method: "post",
    url: "/report/generate",
    data: data
  });
}

export function getSubjectReport(data) {
  return request({
    method: "post",
    url: "/lesson/exist_competencies",
    data: data
  });
}

export function getDetailReportCard(data) {
  return request({
    method: "post",
    url: "/report/detail",
    data: data
  });
}

export function getPredicateList() {
  return request({
    method: "post",
    url: "/grade_score/dropdown"
  });
}

export function editReportCard(data) {
  return request({
    method: "post",
    url: "/report/edit",
    data: data
  });
}

export function getReportCardPerClass(data) {
  return request({
    method: "post",
    url: "report/detail_class",
    data: data
  });
}

export function getStudentReportCard(data) {
  return request({
    method: "post",
    url: "report/detail_student",
    data: data
  });
}

export function downloadReportByClass(data) {
  return request({
    method: "post",
    url: "report/zips",
    data: data
  });
}

export function downloadReport(data) {
  return request({
    method: "post",
    url: "report/pdf_byid",
    data: data
  });
}

export function publishReportByClass(data, type) {
  let url = "report/publish_byclass";
  if (type == "unpublished") url = "report/unpublish_byclass";
  return request({
    method: "post",
    url: url,
    data: data
  });
}

export function publishReportById(data, type) {
  let url = "report/publish_byid";
  if (type == "unpublished") url = "report/unpublish_byid";
  return request({
    method: "post",
    url: url,
    data: data
  });
}
