<template>
  <div :class="g_darkmode ? '' : 'grey lighten-5'" class="pa-4">
    <v-card flat class="pa-4">
      <v-row no-gutters>
        <v-card
          :class="g_darkmode ? 'black' : 'white'"
          class="rounded-lg pa-4"
          flat
          width="100%"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="divider"
              :class="isMob && 'mb-2'"
            >
              <div class="body-2">{{ $t("app.name") }}</div>
              <div class="font-weight-bold">{{ g_user.fullname }}</div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="2"
              class="divider"
              :class="isMob && 'mb-2'"
            >
              <div class="body-2">{{ $t("app.registration_number") }}</div>
              <div class="font-weight-bold">
                {{ g_user.nis }}
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="2"
              class="divider"
              :class="isMob && 'mb-2'"
            >
              <div class="body-2">{{ $t("app.class") }}</div>
              <div class="font-weight-bold">
                {{ g_user.student.class_name }}
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <div class="font-weight-bold">
                <v-select
                  v-model="school_year"
                  :items="schoolYearList"
                  :label="$t('journal.school_year')"
                  :class="$vuetify.breakpoint.smAndUp && 'select-225'"
                  :item-text="
                    item =>
                      `${item.start_year} / ${item.end_year}, Semester ${item.semester}`
                  "
                  :loading="loadingSchoolYear"
                  item-value="id"
                  outlined
                  hide-details
                  dense
                  class="body-2 ma-2"
                  @change="getData()"
                >
                  <template #item="{ item }">
                    <span v-if="item.id !== 'all'" class="caption">
                      {{ item.start_year }} / {{ item.end_year }}, Semester
                      {{ item.semester }}
                    </span>
                    <v-tooltip right>
                      <template #activator="{ on }">
                        <v-icon
                          v-on="on"
                          :color="schoolYearInfo(item.status).color"
                          small
                          class="ml-1"
                          >{{ schoolYearInfo(item.status).name }}</v-icon
                        >
                      </template>
                      <span>{{ schoolYearInfo(item.status).info }}</span>
                    </v-tooltip>
                  </template>
                </v-select>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-card>

    <div v-if="errorMsg" class="error--text">
      <v-divider class="mb-4"></v-divider>
      {{ message }}
    </div>
    <div v-else>
      <ReportCardDetailStudent :dataReport="dataReport" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReportCardDetailStudent from "./ReportCardDetailStudent";
import { getStudentReportCard } from "@/api/admin/academic/reportCard";
import { get_school_year_list } from "@/api/admin/schoolClass";

export default {
  components: {
    ReportCardDetailStudent
  },
  data() {
    return {
      valid: false,
      dataReport: null,
      loadingSave: false,
      school_year: null,
      schoolYearList: [],
      loadingSchoolYear: false,
      errorMsg: false,
      message: ""
    };
  },
  computed: {
    isMob() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    ...mapGetters(["g_user_id", "g_darkmode", "g_user"])
  },
  mounted() {
    this.loadingSchoolYear = true;
    get_school_year_list().then(res => {
      if (res.data.code) {
        this.schoolYearList = res.data.data;
        const schoolYearActive = res.data.data.find(r => r.status == 1);
        if (schoolYearActive) this.school_year = schoolYearActive.id;
        this.loadingSchoolYear = false;
        this.getData();
      }
    });
  },
  methods: {
    async getData() {
      this.dataReport = null;
      this.errorMsg = false;
      const detailReport = await getStudentReportCard({
        school_year: this.school_year
      });

      if (detailReport.data.code) {
        this.dataReport = detailReport.data.data;
      } else {
        this.errorMsg = true;
        this.message = detailReport.data.message;
      }
    },

    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case 1:
          icon = active;
          break;
        case 2:
          icon = willCome;
          break;
      }
      return icon;
    }
  }
};
</script>
